import React from "react";
import PageSection from "../PageSection";

import "./index.scss";

const CertificationSection = () => (
    <PageSection heading="Certification" id="certification">
            <ul>
                <li>Bird Meditech takes pride in announcing that it has recieved All India first Manufacturing license from esteemed government body BIS under
                    Clause IS 13450:Part-2/Sec-21 Infant Radiant Warmer</li>
                <img src="/BIS_cert.JPG" className="image" alt="certificate" />
                <li>Bird Meditech has BIS Certified registarion for LED Phototherapy</li>
                <li>
                    Bird Meditech has also recieved manufacturing licsence approval from CDSCO for following products:- <br></br>
                    LED Phototherapy,<br></br>
                    Infant Radiant Warmer,<br></br>
                    Bubble CPAP<br></br>
                </li>
            </ul>
    </PageSection>
)
export default CertificationSection;